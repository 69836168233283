import React, { FC } from 'react';

import { Box } from 'grommet';
import Intro from './intro';
import Block from './block';
import { ContactForm } from './contactForm';

interface props {
    heading: string;
    intro: string;
}

const ContactBlock: FC<props> = ({ heading, intro }) => (
    <Box background="light-2" className="light-2">
        <Block direction="column">
            <Intro heading={heading} intro={intro} />
            <ContactForm />
        </Block>
    </Box>
);

export default ContactBlock;
